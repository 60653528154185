import jsQR from "jsqr";

export function turnOnCamera(){
    document.getElementById("cameraButton").click()
}

export function renderExpense(target,callback){
    let rtype = {name:"",amount:0}
    renderImageData(target,(i)=>{
        const code = jsQR(i.data, i.width, i.height);

        console.log(code.data.split("\n"))
        const data = code.data.split("\n")

        let name="",amount = 0

        const date = data[3].slice(0,2) + "-" + data[3].slice(2,4) + "-" + data[3].slice(4,8)
        name = data[0] +" "+ date

        if(data.length <= 7){
            amount = data[4]
        }else{
            let items = data.slice(5)
            amount = []
            for(let i=0;i<items.length;i=i+2){
                let price = items[i+1].split(" ")
                amount.push({name:items[i],amount:(Number(price[0]) * (Number(price[1]) + (Number(price[2]) ? Number(price[2]) : 0)))})
            }
        }
        rtype = {name:name,fixed:false,priority:0,amount:amount}
        callback(rtype)

    })
}

function renderImageData(target,callback){
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    console.log(target.files[0])
    const image = new Image;
    image.onload = () => {
          canvas.width = 640
          canvas.height = (image.height/image.width) * 640
          ctx.drawImage(image, 0, 0,canvas.width,canvas.height);
         callback(ctx.getImageData(0,0,canvas.width,canvas.height))
          URL.revokeObjectURL(image.src);
        }
    image.src = URL.createObjectURL(target.files[0]);
}